import {Button, Menu, MenuItem} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {CSVLink} from "react-csv";
import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router";

export default function CampaignToolbarTypeSwitch({onSwitchMode, mode, env}) {


    const getSortTitle = () => {
        if(mode == 'type') return "view by type"
        if(mode == 'recent') return "view recent"
        return "timeline"
    }

    const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
    const sortMenuOpen = Boolean(sortAnchorEl);

    const navigate = useNavigate();

    const handleSortMenuClick = (event) => {
        setSortAnchorEl(event.currentTarget);
    };
    const handleSortMenuClose = () => {
        setSortAnchorEl(null);
    };

    const handleSelect = (type) => {
        onSwitchMode(type)
        handleSortMenuClose()
    }

    return <div className="collapse-all-link">
        <Button
            endIcon={<ArrowDropDownIcon />}
            onClick={handleSortMenuClick}
            size="small"
            sx={{ textTransform: 'none', minWidth: 0, padding: '0px 4px' }}
        >
            {getSortTitle()}
        </Button>
        <Menu
            anchorEl={sortAnchorEl}
            open={sortMenuOpen}
            onClose={handleSortMenuClose}
        >
            <MenuItem onClick={() => {handleSelect('type')}}>view by type</MenuItem>
            <MenuItem onClick={() => {handleSelect('recent')}}>view recent</MenuItem>
            <MenuItem onClick={() => {handleSelect('timeline')}}>timeline</MenuItem>
        </Menu>
    </div>
}