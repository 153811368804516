import {
    Autocomplete,
    Button, Checkbox, Dialog,
    DialogActions,
    DialogContent, DialogTitle, Divider, FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField, Typography,
} from "@mui/material";
import CommonModal from "../CommonModal";
import {TwitterPicker} from "react-color";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import {useDeleteStreamMutation, useUpdateStreamMutation} from "../../../core/reducers/streams";

export default function DeleteStreamDialog(props) {
    const {open, onClose, title, stream} = props;
    const [deleteStream, deleteStreamResponse] = useDeleteStreamMutation();

    const [state, setState] = useState({
        isLoading: false,
    });
    useEffect(()=>{
        if (deleteStreamResponse.isSuccess && !deleteStreamResponse.isUninitialized) {
            console.log(deleteStreamResponse)
            setState({
                ...state,
                isLoading: false
            })
            onClose();
        }
        if (deleteStreamResponse.isError && deleteStreamResponse.error) {
            console.log(deleteStreamResponse.error)
        }

    }, [deleteStreamResponse])

    const handleDelete = () => {
            deleteStream(stream.streamId)
            setState({
                ...state,
                isLoading: true
            })
    }

    return (
        <Dialog onClose={onClose}
                open={open}>
            <DialogTitle>
                {"Delete stream"}
            </DialogTitle>
            <DialogContent>
                Are you sure you want to delete the stream {stream.streamId}?
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={onClose}>
                    Close
                </Button>
                <LoadingButton
                    size="small"
                    onClick={handleDelete}
                    loading={state.isLoading}
                    variant="contained"
                >
                    Delete
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}