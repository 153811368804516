import {Button, Menu, MenuItem} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {CSVLink} from "react-csv";
import React, {useState, useEffect, useRef} from 'react';
import CampaignToolbarTypeSwitch from "./CampaignToolbarTypeSwitch";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

export default function CampaignToolbar({
            showAllGroups, setShowAllGroups,
            showRainbow, setShowRainbow,
            sortByType,setSortByType,
            setCalendarMode,
            generateCSVData, env


}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const getExpandTitle = () => {
        if(showAllGroups == true) return "expand all"
        return "collapse all"
    }
    const getRainbowTitle = () => {
        if(showRainbow == false) return "rainbow"
        return "de-rainbow"
    }


    const handleViewTimeline = () => {
        setCalendarMode({
            active: true,
            env: env
        })
    };
    const handleSortByRecent = () => {
        setSortByType(false);
    };

    const handleSortByType = () => {
        setSortByType(true);
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [mode, setMode] = useState(searchParams.get('viewType') || 'type')
    const navigate = useNavigate();

    const onSwitchMode = (type) => {
        setMode(type)
        switch (type) {
            case 'type':
                setSortByType(true);
                break;
            case 'recent':
                setSortByType(false);
                break;
            case 'timeline':
                return navigate("/timeline?timelineEnv=" + env);
                break
                // setCalendarMode({
                //     active: true,
                //     env: env
                // })
                // break;
        }

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('viewType', type);
        setSearchParams(newSearchParams);
    }

    return <div className="collapse-controls" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="collapse-all-link" onClick={() => { setShowAllGroups(!showAllGroups); }}>
            <div>{getExpandTitle()}</div>
        </div>
        &nbsp;
        <div className="collapse-all-link" onClick={() => {
            setShowRainbow(!showRainbow);
            window.localStorage.setItem("show_rainbow_" + env, !showRainbow)
        }}>
            <div>{getRainbowTitle()}</div>
        </div>
        &nbsp;
        <CampaignToolbarTypeSwitch
            onSwitchMode={onSwitchMode}
            mode={mode}
            env={env}
        />
        &nbsp;
        {/*<div className="collapse-all-link">*/}
        {/*    <Button*/}
        {/*        endIcon={<ArrowDropDownIcon />}*/}
        {/*        onClick={handleSortMenuClick}*/}
        {/*        size="small"*/}
        {/*        sx={{ textTransform: 'none', minWidth: 0, padding: '0px 4px' }}*/}
        {/*    >*/}
        {/*        {getSortTitle()}*/}
        {/*    </Button>*/}
        {/*    <Menu*/}
        {/*        anchorEl={sortAnchorEl}*/}
        {/*        open={sortMenuOpen}*/}
        {/*        onClose={handleSortMenuClose}*/}
        {/*    >*/}
        {/*        <MenuItem onClick={handleSortByType}>view by type</MenuItem>*/}
        {/*        <MenuItem onClick={handleSortByRecent}>view recent</MenuItem>*/}
        {/*        <MenuItem onClick={handleViewTimeline}>timeline</MenuItem>*/}
        {/*    </Menu>*/}
        {/*</div>*/}

        <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose}>
                    <CSVLink separator={";"} data={generateCSVData()} filename={"campaigns.csv"}>Export to CSV</CSVLink>
                </MenuItem>
            </Menu>
        </div>
    </div>
}