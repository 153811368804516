import {Fragment, useEffect, useRef, useState} from "react";
import Timeline from "./Timeline";
import {useGetAllCampaignsQuery} from "../core/reducers/campaign";
import {getEnvName} from "../components/Utils/CommonUtils";
import {useSelector} from "react-redux";
import CampaignToolbarTypeSwitch from "../components/Publisher/Campaign/CampaignToolbarTypeSwitch";
import {useSearchParams} from "react-router-dom";
import {useHistory, useNavigate} from "react-router";
import {useGetStreamsQuery} from "../core/reducers/streams";
import {useGetEventsQuery} from "../core/reducers/calendar";
import dayjs from "dayjs";

const RESOURCE_TYPE_1 = 'Stream 1';
const RESOURCE_TYPE_2 = 'Stream 2';

const resource = [{id : "a", name : RESOURCE_TYPE_1},
    {id : "b", name : RESOURCE_TYPE_2}];


//TODO: dev component, remove it after all works will be done with Timeline
export default function TimelineCampaignsProvider({dateFilter}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [env, setEnv1] = useState(getEnvName('env', sourceMode, searchParams))


    const getFiltered = (campaigns, streams, calendarEvents) => {
        let camps = [];
        campaigns.forEach(camp => {
            if (camp.distribution.type == 'permanent') return false
            //
            // if (filterText !== '' && camp.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return false;
            //
            // if (filterType !== undefined && filterType !== 'All' && filterType != null) {
            //     if (camp.type != filterType) return false;
            // }
            //
            // if (filterStatuses !== undefined && filterStatuses.length > 0) {
            //     if (!filterStatuses.includes(camp.status)) return false;
            // }

            if((camp.distribution.type=='float' && camp.distribution.till >= 32503680000) ||
                (camp.distribution.type=='permanent' && camp.distribution.till >= 32503680000)) {
                return false
            }

            //from till
            camps.push({
                resourceId: findResourceId(camp.type, streams.streams),
                name: camp.id,
                durationUnit:'h',
                startDate: convertTimestampToFormattedDate(camp.distribution.from),
                endDate: convertTimestampToFormattedDate(camp.distribution.till),
                campaignType: camp.type,
                eventColor: findEventColor(camp.type, streams.streams),
                eventStyle: 'colored',
                asanaGid: calendarEvents ? calendarEvents.find(event => event.eventId === camp.id)?.asanaTaskGid : null,
                calendarId: calendarEvents ? calendarEvents.find(event => event.eventId === camp.id)?.id : null

            })
        });

        return camps
    }

    const findResourceId = (event, streams) => {
        let resourceId = '';
        if (streams === undefined || streams === null) return resourceId;
        streams.forEach(stream => {
            stream.campaignTypes.forEach( campaignType => {
                if (campaignType.typeId === event) {
                    resourceId = stream.streamId;
                }
            })
        })
        return resourceId;
    }

    const findEventColor = (event, streams) => {
        let color = '#000000';
        if (streams === undefined || streams === null) return color;
        streams.forEach(stream => {
            stream.campaignTypes.forEach( campaignType => {
                if (campaignType.typeId === event) {
                    color = campaignType.color;
                }
            })
        })
        return color;
    }

    const currentYear = new Date().getFullYear();

    const query = {}
    query.env = searchParams.get('timelineEnv') || env;
    if(dateFilter!=undefined && dateFilter?.from!= '' && dateFilter?.to != '') {
        query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
        query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);
    } else {
        query.from = Math.round(new Date(currentYear, 0, 1).getTime() / 1000); // 1 января текущего года
        query.till = Math.round(new Date(currentYear, 11, 31, 23, 59, 59, 999).getTime() / 1000); // 31 декабря текущего года
    }

    const currentTime =  Math.round(new Date(query.from).setHours(0,0,0,0)/1000)
    const {data: calendarEvents, isUninitialized: isUninitializedEvents, isSuccess, refetch} = useGetEventsQuery(currentTime);

    const { data, isLoading, isError, isUninitialized, error } = useGetAllCampaignsQuery(query,{});
    const { data : streams,  isLoading: streamsLoading, isError: streamsIsError} =  useGetStreamsQuery();


    //const [events, setEvents] = useState([]);

    const events = data == undefined || streams ==undefined || calendarEvents == undefined ? [] : getFiltered(data.campaigns, streams, calendarEvents)

    const [resource, setResource] = useState([]);

    const [isDataReady, setIsDataReady] = useState(false);

    function getRandomChar() {
        const chars = 'ab';
        const randomIndex = Math.floor(Math.random() * chars.length);
        return chars[randomIndex];
    }

    function getRandomColor() {
        const colors = ["orange", "blue", "green", "red", "pink"];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    }

    function convertTimestampToFormattedDate(timestampInSeconds) {
        return dayjs(timestampInSeconds * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')

    }



    useEffect(() => {
        if (!isLoading &&  !streamsLoading && !streamsIsError &&
            !isError && data && data.campaigns != null && streams && streams.streams!=null) {
            // let camps = [];
            // data.campaigns.forEach(camp => {
            //     if (camp.distribution.type == 'permanent') return false
            //
            //     if (filterText !== '' && camp.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return false;
            //
            //     if (filterType !== undefined && filterType !== 'All' && filterType != null) {
            //         if (camp.type != filterType) return false;
            //     }
            //
            //     if (filterStatuses !== undefined && filterStatuses.length > 0) {
            //         if (!filterStatuses.includes(camp.status)) return false;
            //     }
            //
            //     //from till
            //     camps.push({
            //         resourceId: findResourceId(camp.type, streams.streams),
            //         name: camp.id,
            //         durationUnit:'h',
            //         startDate: convertTimestampToFormattedDate(camp.distribution.from),
            //         endDate: convertTimestampToFormattedDate(camp.distribution.till),
            //         campaignType: camp.type,
            //         eventColor: findEventColor(camp.type, streams.streams),
            //         eventStyle: 'border'
            //
            //     })
            // });
            setIsDataReady(true);
            //setEvents(camps);
            let resource = [];
            streams.streams.forEach(stream => {
                    resource.push({
                        id: stream.streamId,
                        name: stream.streamId,
                        priority: stream.priority
                    })
            })
            setResource(resource);
            refetch();
        }
    }, [data, isLoading, isError, isUninitialized, error, streams]);

    const navigate = useNavigate();

    return (
        <Fragment>
            <Timeline
                resource={resource}
                events={events}
                isDataReady={isDataReady}
                isError={isError}
                error={error}
                eventsDateFrom={dateFilter?.from ? dateFilter?.from : new Date()}
                calendarEvents={calendarEvents}
            />
        </Fragment>);
}