import {
    Box,
    Button, Checkbox, Chip,
    Container,
    Grid,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import {useEffect, useState} from "react";
import {getAllowedTypes, isAdmin} from "../../Utils/CommonUtils";
import Loading from "../../Loading";
import {useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {Alert} from "@mui/lab";
import {useDeleteStreamMutation, useGetStreamsQuery, useUpdateStreamMutation} from "../../../core/reducers/streams";
import {useGetAllCampaignTypesQuery} from "../../../core/reducers/campaignType";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import Stack from '@mui/material/Stack';
import StreamCard from "./StreamCard";
import UpdateStreamDialog from "./UpdateStreamDialog";

export default function Streams() {
    const userSession = useSelector((state) => state.data.UserSession);
    const [state, setState] = useState({
        isUpdateDialogOpen: false,
        selectedStream: {},
        allowedTypes: [],
        allTypes: [],
        isLoading: true,
        streamsList: []
    })
    const [streamData, setStreamData] = useState([]);

    const {
        data: streams,
        isLoading,
        isSuccess: isStreamSuccess,
        isError,
        error,
        refetch
    } = useGetStreamsQuery()
    const [updateStream, updateStreamResponse] = useUpdateStreamMutation();
    const {
        data: types,
        isSuccess: isTypesSuccess,
        status: typeStatus,
        refetch: refetchTypes
    } = useGetAllCampaignTypesQuery({},{})


    useEffect(() => {
        if (isStreamSuccess && isTypesSuccess) {
            setState({
                ...state,
                isLoading: false,
                allowedTypes: getAllowedTypes(Object.keys(types), streams.streams),
                allTypes: Object.keys(types),
                streamsList: streams.streams
            })
        }

    }, [types, typeStatus, streams])


    const handleCreateStream = () => {
        setState({
            ...state,
            isUpdateDialogOpen: true
        })
    }
    const handleCloseDialog = () => {
        setState({
            ...state,
            isUpdateDialogOpen: false
        })
        refetch();
        refetchTypes();
    }

    const updateStreamsPriority = (updatedList) => {
        updatedList.forEach(item => {
            updateStream(item.content)
        })
    };

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;

        let updatedList = JSON.parse(JSON.stringify(streamData))
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList.map(x => {
            x.content.priority = updatedList.indexOf(x)
        })
        setStreamData(updatedList);
        updateStreamsPriority(updatedList);
    };


    useEffect(() => {
        if (!streams) return;
        let data = streams.streams;
        setStreamData(data.map(x => {
            return {
                id : x.streamId,
                content : x
            }
        }).sort(function (a,b){
            let indexA = a.content.priority;
            let indexB = b.content.priority;
            if (indexA < indexB) {
                return -1;
            }
            return 1;
        }))
    }, [streams]);

    return (
        <>
            <Container>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h1>Timeline streams configuration </h1>

                    {isAdmin(userSession) && <Button variant="contained"
                                                     onClick={() => handleCreateStream()}
                    >
                        Create new stream
                    </Button>}
                </Box>

                {updateStreamResponse.isError && <Alert sx={{mt:2}} severity={"error"}>
                    {JSON.stringify(updateStreamResponse.error)}
                </Alert>}
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box pt={0} style={{ flexGrow: 1 }}>
                            <Loading isLoading={isLoading} isError={isError} error={error}>
                                <Box sx={{display: 'flex', flexWrap: 'wrap'}} className={"stream-cards"}>
                                    {
                                        isStreamSuccess && streamData !== 0 ?
                                            <DragDropContext onDragEnd={handleDrop}>
                                                <Droppable droppableId="list-container" sx={{width:'100%'}}>
                                                    {(provided) => (
                                                        <div
                                                            className={"droppable-field"}
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {streamData && streamData.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id} index={index} >
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.dragHandleProps}
                                                                            {...provided.draggableProps}
                                                                        >
                                                                            <StreamCard
                                                                                key={`stream${index}`}
                                                                                stream={item.content}
                                                                                streamsList={state.streamsList}
                                                                                allowedTypes={state.allTypes}
                                                                                refetchSteams={refetch}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext> :
                                            <Typography>
                                                No streams yet
                                            </Typography>
                                    }
                                </Box>
                            </Loading>
                        </Box>
                        <UpdateStreamDialog
                            open={state.isUpdateDialogOpen}
                            onClose={handleCloseDialog}
                            title={"Create stream"}
                            stream={null}
                            streamsList={state.streamsList}
                            types={state.allTypes}
                            isCreateStream={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Box>
                            {state.allowedTypes && (
                                <Box>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    mb: 2
                                }}>
                                    Non-distributed types:
                                </Typography>
                                <Stack spacing={{ xs: 1, sm: 2 }}
                                       direction="row"
                                       useFlexGap
                                       sx={{ flexWrap: 'wrap' }}>
                                    {state.allowedTypes.map((type, index) => (
                                        <Chip key={index} label={type} />
                                    ))}
                                </Stack>
                                </Box>)}
                        </Box>
                    </Grid>
                </Grid>


            </Container>
        </>
    )

}