import React from "react";
import {Chip} from "@mui/material";

export default function AudienceRange({audience}) {

    const titles = {}

    const getTitle = (param) => {
        if (titles.hasOwnProperty(param)) return titles[param];
        return param;
    }

    const getLabel = () => (
        <>
            {audience.from != null && audience.from !== '' &&
                <>
                    {getTitle(audience.parameter)}&nbsp;
                    {(audience.fromIncluded === true || audience.fromIncluded === null) ? '>=' : '>'}&nbsp;{audience.from}
                </>
            }
            {(audience.to != null && audience.to !== '' && audience.from!=null && audience.from!=='') && <>; </>}
            {(audience.to != null && audience.to !== '') &&
                <>
                    {getTitle(audience.parameter)}&nbsp;
                    {audience.toIncluded ? '<=' : '<'}&nbsp;
                    {audience.to != null ? audience.to : '∞'}
                </>
            }
        </>
    )

    return (
        <Chip label={getLabel()}/>

    )

}