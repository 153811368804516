import {configureStore, createSlice, isRejectedWithValue} from "@reduxjs/toolkit"
import api from "./api/base-api"
import {calendarApi} from "./api/base-api"
import root from "./reducers/index"
import {getDomainAlias, parseFormDataTextToJsonString} from "../constants";


import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: "https://07758c2ab1ee4c309578f08e4f8ed6f3@cross-sentry.belka-games.com/35",
    environment: window.location.hostname!='localhost'?'production':'local',
    tracesSampleRate: 1.0,
    release: getDomainAlias()
});


window.onerror = function (message, source, lineno, colno, error) {
    console.error('Global onerror caught:', message, source, lineno, colno, error);
    Sentry.captureException(error || new Error(message));
};

window.onunhandledrejection = function (event) {
    console.error('Unhandled promise rejection:', event.reason);
    Sentry.captureException(event.reason || new Error('Unhandled rejection'));
};

const myLoggingMiddleware = storeAPI => next => action => {
    if (isRejectedWithValue(action)) {
        const jsonError = JSON.stringify(action.payload.data);
        if (action.meta.baseQueryMeta.request.body != null) {
            action.meta.baseQueryMeta.request.body.getReader().read().then(response => {
                const jsonRequest = JSON.stringify({body: parseFormDataTextToJsonString(new TextDecoder().decode(response.value))});
                Sentry.captureMessage(
                    `Error during request \n${jsonError}\n${jsonRequest}`,
                    Sentry.Severity.Error
                )
            });
        } else {
            if (action.payload.status == 403 || action.payload.status == 401) {
                Sentry.captureMessage(
                    `Server error ${action.payload.status}  ${jsonError}`,
                    Sentry.Severity.Info
                )
            } else if (action.payload.status !== 404) {
                Sentry.captureMessage(
                    `Response error ${action.payload.status} ${jsonError}`,
                    Sentry.Severity.Error
                )
            }
        }
    }
    return next(action)
}

const authSlice = createSlice({
    name: 'auth_',
    initialState: {
        token: null,
        error: null
    },
    reducers: {
        setUser: (state, action) => { state.token = action.payload; },
        setError: (state, action) => { state.error = action.payload; }
    }
});
export const { setUser, setError } = authSlice.actions;


export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [calendarApi.reducerPath]: calendarApi.reducer,
        data: root,
        auth_: authSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware).concat(calendarApi.middleware)
});


