/**
 * Application configuration
 */
import {getCalendarLink, getLinkByTaskGid, hasText} from "../components/Utils/CommonUtils";

let startDate = new Date();
startDate.setDate(startDate.getDate() - 2*365);
let endDate = new Date();
endDate.setDate(endDate.getDate() + 2*365);
const schedulerConfig = {
    timeRangesFeature: {
        showCurrentTimeLine: {name: "now", cls: "current-time-line"},
        showHeaderElements: true,
        tooltipTemplate({timeRange}) {
            if (timeRange?.data?.info) {
                return `${timeRange.data.info}`
            }
        }
    },
    presets: [
        {
            base: 'monthAndYear',
            id: 'monthAndYear'
        },
        {
            base: 'weekAndMonth',
            id: 'weekAndMonth'
        },
        {
            base: 'weekAndDay',
            id: 'weekAndDay0',
            tickWidth: 30,
            shiftUnit: 'day',
            headers: [
                {
                    unit: 'month',
                    align: 'center',
                    dateFormat: 'MMM YYYY'
                },
                {
                    unit: 'd',
                    align: 'center',
                    dateFormat: 'dd',
                },
                {
                    unit: 'd',
                    align: 'center',
                    dateFormat: 'DD'
                }
            ]
        },
        {
            base: 'weekAndDay',
            id: 'weekAndDay1',
            tickWidth: 60,
            shiftUnit: 'day',
            headers: [
                {
                    unit: 'month',
                    align: 'center',
                    dateFormat: 'MMM YYYY'
                },
                {
                    unit: 'week',
                    align: 'center',
                    dateFormat: 'dd'
                },
                {
                    unit: 'day',
                    align: 'center',
                    dateFormat: 'DD'
                }
            ]
        },
        {
            base: 'weekAndDay',
            id: 'weekAndDay2',
            tickWidth: 120,
            shiftUnit: 'day',
            headers: [
                {
                    unit: 'month',
                    align: 'center',
                    dateFormat: 'MMM YYYY'
                },
                {
                    unit: 'd',
                    align: 'center',
                    dateFormat: 'DD'
                },
                {
                    unit: 'd',
                    align: 'center',
                    dateFormat: 'DD'
                }
            ]
        }
    ],
    viewPreset: "weekAndDay0",
    timeResolution: {
        unit: 'hour',  //Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
        increment: 1
    },
    rowHeight: 35,
    barMargin: 1,
    startDate: startDate,
    endDate: endDate,
    columns: [
        {
            type: 'resourceInfo',
            text: 'Mephisto campaign',
            field: 'name',
            width: 200,
            showImage: false,
            editor: false
        }
    ],
    eventDragFeature: {
        constrainDragToResource: false
    },
    readOnly: true,
    rowCopyPasteFeature: false,
    eventResizeFeature: false,
    nonWorkingTimeFeature: true,
    createEventOnDblClick: false,
    useInitialAnimation: false,
    enableEventAnimations: false,
    infiniteScroll: true,
    eventMenuFeature: {
        items: {
            cutEvent: false,
            copyEvent: false,
            deleteEvent: false,
            editEvent: false,
            eventLink: {
                text: 'Link to Asana',
                icon: "b-fa b-fa-fw b-fa-external-link-alt",
                weight: 0,
                onItem({eventRecord}) {
                    if (hasText(eventRecord.data.asanaGid)) {
                        window.open(getLinkByTaskGid(eventRecord.data.asanaGid), "_blank")
                    }
                }
            },
            calendarLink: {
                text: 'Link to Calendar',
                icon: "b-fa b-fa-fw b-fa-external-link-alt",
                weight: 0,
                onItem({eventRecord}) {
                    if (eventRecord.data.calendarId) {
                        window.open(getCalendarLink(eventRecord.data.calendarId), "_blank")
                    }
                }
            },
        },
        processItems({eventRecord, items}) {

            if (eventRecord.data.asanaGid === undefined && eventRecord.data.calendarId === undefined) {

                    items.noLinks = {
                        text : 'No links found',
                        onItem({eventRecord}) {
                        }
                    };
            }
            if (eventRecord.data.asanaGid === null || eventRecord.data.asanaGid === undefined) {
                items.eventLink.hidden = true;
            }
            if (eventRecord.data.calendarId === null || eventRecord.data.calendarId === undefined) {
                items.calendarLink.hidden = true;
            }
        }
    },
}

export { schedulerConfig };
