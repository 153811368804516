import {AbTestConfigs, CampaignConfigs, SEARCH_TYPES} from "../../core/reducers/typeFilter";
import {configs, getDomainAlias} from "../../constants";
import {MODE} from "../Campaign/campaignMode";
import dayjs from "dayjs";

const domain_constants = {
    'cb': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'bs': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'wj': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'hpr': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'ba': {
        'DEVELOP_ENV':'development',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'rt': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''

    },
    'toh': {
        'DEVELOP_ENV':'develop',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'cm': {
        'DEVELOP_ENV':'staging',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'local': {
        'DEVELOP_ENV':'development',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    },
    'staging': {
        'DEVELOP_ENV':'development',
        'LIVEOPS_ENV':'liveops',
        'PROD':'production',
        'ROLE_SUFFIX':''
    }
}
export const CAMPAIGNS = "campaigns";
export const AB_TESTS = "abtests";
export const AB_TESTS_MINI = "abtestsmini";
export const TEMPLATES = "templates";

export const DEVELOP_ENV = domain_constants[getDomainAlias()]['DEVELOP_ENV'];
export const LIVEOPS_ENV = domain_constants[getDomainAlias()]['LIVEOPS_ENV'];

export const DEFAULT_ENV = DEVELOP_ENV;
export const DEFAULT_ENV2 = LIVEOPS_ENV;

export const PROD = domain_constants[getDomainAlias()]['PROD'];

export const GROUP_ADMIN = "AdminProd"+domain_constants[getDomainAlias()]['ROLE_SUFFIX'];;//AdminProd
export const GROUP_VIEWER = "ViewerProd"+domain_constants[getDomainAlias()]['ROLE_SUFFIX'];;
export const GROUP_STAGE = "AdminStage"+domain_constants[getDomainAlias()]['ROLE_SUFFIX'];;


export function hasContent(string) {
    return string !== null && string !== undefined && string !== '' && string !== 'null';
}


export function getHistoryFromStorage(ev, type) {
    let typesConfigs = getFromStorage(`editHistory-${ev}`) ?
        JSON.parse(getFromStorage(`editHistory-${ev}`))
            .map(c => {
                if (c.split('|')[1] === SEARCH_TYPES.CAMPAIGNS) {
                    return new CampaignConfigs(c.split('|')[0]);
                }
                return new AbTestConfigs(c.split('|')[0]);
            }).reverse() : [];

    if (type === SEARCH_TYPES.ALL) {
        return typesConfigs;
    }

    return typesConfigs.filter(t => t.type === type);
}

export function getFromStorage(key) {
    return window.localStorage.getItem(key);
}

export function setInStorage(key, value) {
    window.localStorage.setItem(key, value)
}

export function saveHistory(campaignId, ev, type) {
    let editHistory = getFromStorage(`editHistory-${ev}`);
    editHistory = editHistory ? JSON.parse(editHistory) : []
    if (campaignId !== 'null' && !editHistory.includes(`${campaignId}|${type}`)) {
        editHistory.push(`${campaignId}|${type}`)
    }
    // window.dispatchEvent(new Event("previousEditsChanged"))
    window.localStorage.setItem(`editHistory-${ev}`, JSON.stringify(Array.from(editHistory)))
}

export function deleteHistory(ev) {
    window.localStorage.removeItem(`editHistory-${ev}`)
}

export function getEnvFromUrl(envSearch) {
    const urlParams = new URLSearchParams(window.location.search);
    if (envSearch === 'env') {
        return urlParams.get('env') || DEFAULT_ENV;
    } else if (envSearch === 'env2') {
        return urlParams.get('env2') || DEFAULT_ENV2;
    }
}
export function getModeFromUrl() {
    let locationString = window.location.href;
    if (locationString.indexOf(AB_TESTS) !== -1) {
        return AB_TESTS;
    } else if (locationString.indexOf(TEMPLATES) !== -1) {
        return TEMPLATES;
    } else if (locationString.indexOf(AB_TESTS_MINI) !== -1) {
        return AB_TESTS_MINI;
    } else {
        return CAMPAIGNS;
    }
}

export function isCampaignMode(appMode) {
    return appMode === CAMPAIGNS;
}
/**
 * Показываем app mode только на страницах creator и паблишер
 * */
export function isNeedToShowMode() {
    return window.location.href.indexOf('creator') !== -1
        || window.location.href.indexOf('publisher') !== -1
        || window.location.pathname == '/';
}
/**
 * Ипользуйте этот метод для опрделение текущего env в качестве параметров
 * envForSearch - имя какого env хотим найти env или env2
 * sourceMode - глобальный стейт
 * searchParam - параметры строки поиска
 * */
export function getEnvName(envForSearch, sourceMode, searchParam) {

    if (searchParam.get('env') == null && searchParam.get('env2') == null) {
        return envForSearch === 'env' ? sourceMode.env1Name : sourceMode.env2Name;
    }

    if (searchParam.get('env2') == null) {
        return envForSearch === 'env' ? searchParam.get('env') : (sourceMode.env2Name || LIVEOPS_ENV);
    }

    if(envForSearch === 'env2')
        return searchParam.get('env2') ? searchParam.get('env2') : (sourceMode.env2Name || LIVEOPS_ENV)
    if(envForSearch === 'env')
        return searchParam.get('env') ?searchParam.get('env') : (sourceMode.env1Name || DEVELOP_ENV);


    // For searchParam.size === 2
    const env2Value = searchParam.get('env2');
    if (envForSearch === 'env') {
        return env2Value === PROD ? LIVEOPS_ENV : searchParam.get('env');
    }
    return env2Value === PROD ? PROD : env2Value;
}

export function sortCampaignByDistribution(a, b) {
    return (b.distribution.from - a.distribution.from)
}
export function sortCampaign(a, b) {
    if(a.id.slice(0,2)==='ND') return (b.distribution.from - a.distribution.from)
    if (a.id < b.id) {
        return 1;
    }
    if (a.id > b.id) {
        return -1;
    }
    return 0;
}

export function getFullEnvironment(env) {
    const envList = JSON.parse(window.localStorage.getItem('envList'));
    return envList!=null&&envList.hasOwnProperty(env) ? envList[env] : null;
}

export function isEmpty(str) {
    return !str || str === '';
}

export function isValidInteger(num) {

    return /^-?(0|[1-9]\d*)$/.test(num);
}

export function isValidJson(str) {
    if (str ==='[]' || str === 'null') {
        return false;
    }
    try {
        JSON.parse(str);
        if (Array.isArray(JSON.parse(str))) {
            return false;
        }
    } catch (e) {
        return false;
    }
    return true;
}

export function containsRestrictedChars(str, chars) {
    for (const char of chars) {
        if (str.includes(char)) {
            return true;
        }
    }
    return false;
}

export function setSearchType(type, dispatch) {
    dispatch({
        type: 'TYPE_FILTER', payload: {
            data: {
                type: type
            }
        }
    });
}

export function sortAlphabeticallyByOneField(arr, fieldName) {
    arr.sort((a,b) => {

        const nameA = a[fieldName].toUpperCase();
        const nameB = b[fieldName].toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
}

export function getColor(mode) {
    switch (mode) {
        case MODE.UPDATE:
            return 'red';
        case MODE.CREATE:
        case MODE.CLONE:
            return 'green';
    }
}

export function getDuplicatesAndUniques(arr) {
    let uniques = new Set();
    let duplicates = new Set();

    let obj = {}

    arr.forEach(i => {
        if(obj.hasOwnProperty(i)) {
            obj[i] = obj[i] + 1;
        } else {
            obj[i] = 1;
        }
    })
    Object.keys(obj).forEach(key => {
        if (obj[key] === 1) {
            uniques.add(key)
        } else {
            duplicates.add(key)
        }
    })

    return {duplicates, uniques};
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function convertToDayjs(unixTimestamp) {
    try {
        if (unixTimestamp < 9999999) {
            unixTimestamp = unixTimestamp * 1000
        }
        let d = dayjs(new Date(unixTimestamp * 1000)).set('second', 0);
        return d;
    } catch (e) {
        return ''
    }
}

export function isProdEnv(env) {
    return env == PROD
}

export function isProdAdmin(env, userSession) {
    return (env !== 'prod' && env !== 'production') || (userSession.user != null && Object.values(userSession.user).includes(GROUP_ADMIN));
}
export function isProdViewer(userSession) {
    return (userSession.user != null && Object.values(userSession.user).includes(GROUP_VIEWER));
}
export function isAdmin(userSession) {
    return (userSession.user != null && Object.values(userSession.user).includes(GROUP_ADMIN));
}
export function isStageAdmin(userSession) {
    return (userSession.user != null && Object.values(userSession.user).includes(GROUP_STAGE));
}

function jwtDecode(token) {

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
export function isTokenExpired(token) {
    try {
        const decodedToken = jwtDecode(token); // Use a library like jwt-decode
        const currentTime = new Date().getTime() / 1000;
        return decodedToken.exp < (currentTime+5*60);
    } catch (e) {
        return true;
    }
}

export const findCampaignType = (currentType, streams) => {
    if (streams.length === undefined || streams.length === 0) return false;

    let isActiveType = false;
    streams.forEach(stream => {
        if (stream.campaignTypes.length !== 0) {
            stream.campaignTypes.forEach(type => {
                if (type.typeId === currentType) {
                    isActiveType = true;
                }
            })
        }
    });
    return isActiveType;
}

export const getAllowedTypes = (types, streamsList) => {
    let allowed = [];
    types.forEach(type => {
        if (!findCampaignType(type, streamsList)) {
            allowed.push(type);
        }
    })
    return allowed;
}

export const calendarDateOn = (calendarDueOn) => {
    const formattedDateStr = calendarDueOn.replace('T', ' ').slice(0, 16);
    const date = stringToDate(formattedDateStr);
    return date;
}

export const stringToDate = (strDate) => {
    const date = new Date(strDate);
    return date;
}
export const isExists = (value) => value !== undefined && value !== null

export const isString = (value) =>
    isExists(value)
    && typeof value === "string"

export const hasText = (value) =>
    isString(value)
    && value.trim().length > 0


export const getLinkByTaskGid = (gid)  =>{
    return "https://app.asana.com/0/0/" + gid;
}
export const getCalendarLink = (eventId)  =>{
    let config = configs[getDomainAlias()].calendarGameUrl;
    return config + '?event='+ eventId;
}
export const SHORT_CAMPAIGN_NAMES_ = {
    "AdventurePass": "AP",
    "special_event_interactive": "SE (IE)",
    "special_event_revert": "SE (SER)",
    "special_event_blockers": "SE (SEB)",
    "special_event_blockers_for_energy": "SE (SEBFE)",
    "special_event_marathon": "SE (MARATHON)",
    "special_event_marathon_short": "SE (MARATHON_SHORT)",
    "special_event_battle_pass": "SE (SEBP)",
    "special_event_moves_pool": "SE (SEMP)",
    "special_event_marathon_battlepass": "SE (MARATHON_BATTLEPASS)",
    "special_event_time_pressing": "SE (SETP)",
    "special_event_risk": "SE (SE_RISK)",
    "special_event_journey": "SE (SEJ)",
    "force_update": "FU",
    "promo": "PROMO",
    "new_town": "NT",
    "new_district": "ND",
    "temporary_town": "TT",
    "subscription": "SUB",
    "bank": "BANK",
    "deeplink_offer_pack": "DOP",
    "start_offer": "SO",
    "avatars": "AVA",
    "battle_pass": "BP",
    "battle_pass_rework": "BPR",
    "farm_offer": "FTO",
    "decoration_event": "DE",
    "astronomical_clock": "AC",
    "cascading_event": "CAS",
    "special_event_staged_default": "SE",
    "black_friday": "BF",
    "unique_pack_offer": "UP",
    "segmented_offer": "SO",
    "double_offer": "DOO",
    "tournament_offer_cards": "TOC",
    "tournament_offer_cards_rework": "TOCR",
    "tournament_offer_colors": "TO (TO_COLORS)",
    "tournament_offer_bonuses": "TO (TO_BONUSES)",
    "tournament_offer_streaks": "TOS",
    "tournament_offer_champions": "TOCH",
    "train_offer": "TRO",
    "casket_offer": "MCO",
    "strongbox_offer": "SBO",
    "lantern_offer": "LO",
    "lottery_offer": "LTO",
    "bingo_offer": "BTO",
    "news_message": "NM",
    "tournament_offer_medals": "TOM",
    "tournament_offer_airships": "TOA",
    "offer_pack": "OP",
    "pioneer_offer": "PO",
    "following_offer": "FO",
    "clan_chest_offer": "CCH",
    "clan_tournament_offer": "CTO",
    "cooking_event": "CE",
    "mini_games_offer": "MGO",
    "happy_hours": "HHO",
    "ml_offer": "MLO",
    "direct_purchase_offer": "DPO",
    "preloader": "PL",
    "regular_offer": "RO",
    "cross_promo": "CRP",
    "cryptex_offer": "CO",
    "fortune_tower": "FRT",
    "piggy_bank": "PB",
    "tournament_offer_pvp": "PVP",
    "trigger_offer": "TRGO",
    "digging_event": "DGE",
    "vips_offer": "VIPO",
    "merge_offer": "MO",
    "tournament_offer_derby": "DTO",
    "hidden_objects_game": "HOG",
    "offers_token": "OT",
    "start_offer_ml": "STOML",
    "tournament_offer_bowling": "TOB",
    "pre_defeat_offer": "PDO",
    "mahjong_offer": "MJO",
    "board_kings": "BK",
    "coloring_offer": "CLO",
    "tournament_offer_slot_machine": "SMTO",
    "conveyor_offer": "CNV",
    "expedition_event": "EE",
    "merge_dressup_offer": "MDO",
    "season_shop": "SS",
    "city_park": "CTP",
    "tournament_offer_gems": "TOG",
    "email_collect": "EO",
    "start_generator_offer": "SGO",
    "snakes_and_ladders_event": "SLE",
    "multi_slot_offer": "MSO",
    "tournament_offer_weekly_contest": "TOWC",
    "saga_challenge": "SCH",
    "tournament_offer_streak_duels": "TOSD",
    "team_city_decor": "TCD",
    "tournament_offer_with_bet": "TOWB",
    "digging_event_v2": "DIG",
    "material_quests": "MQ",
    "prize_piece": "PP",
    "trigger_offer_rework": "TRGOR",
    "long_streak": "LS",
    "tournament_offer_clans_contest": "TOCC",
    "bank_coupon_offer": "BCO",
    "lava_quest": "LQ",
    "merge_event_v2": "MRG",
    "start_quest": "SQ",
    "universal_offer": "UNI",
    "tournament_offer_temporary_town": "TOTT",
    "mini_games_2_offer": "MG2O",
    "tournament_offer_clans_helps_contest": "TOHC",
    "candlestick_event": "CSE",
    "level_chain": "LC",
    "seasonal_collections": "SEC"
}