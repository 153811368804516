import api from "../api/base-api"

const streamApi = api.injectEndpoints({
    endpoints: (build) => ({

        getStreams: build.query({
            query: () => {
                return {
                    url: '/admin/stream'
                }
            },
        }),
        updateStream: build.mutation({
            query: (data) => {
                return {
                    url: '/admin/stream',
                    body: data,
                    method: "POST",
                }
            }
        }),
        deleteStream: build.mutation({
            query: (streamId) => {
                return {
                    url: `/admin/stream?streamId=${streamId}`,
                    method: "DELETE"
                }
            }
        })
    }),
    }
)
export const {
    useGetStreamsQuery,
    useUpdateStreamMutation,
    useDeleteStreamMutation
} = streamApi;
