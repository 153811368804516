import {
    Autocomplete,
    Button, Checkbox,
    DialogActions,
    DialogContent, Divider, FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField, Typography,
} from "@mui/material";
import CommonModal from "../CommonModal";
import {TwitterPicker} from "react-color";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import {useUpdateStreamMutation} from "../../../core/reducers/streams";
import {Box, maxHeight} from "@mui/system";
import {getAllowedTypes} from "../../Utils/CommonUtils";

export default function UpdateStreamDialog(props) {
    const DEFAULT_COLOR= '#FFA500'
    const DEFAULT_COLORS = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC',
        '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#F700FF', '#5500FF', '#E6FF00', '#0C5752'];
    const {open, onClose, title, stream, streamsList, types, isCreateStream} = props;
    const [updateStream, updateStreamResponse] = useUpdateStreamMutation();

    const getTypeColorsMap = (stream) => {
        if (stream === undefined || stream === null || stream.campaignTypes.length === 0) return new Map();
        let typeColorMap = new Map();
        stream.campaignTypes.forEach( campaignType => {
            typeColorMap.set(campaignType.typeId, campaignType.color !== null ? campaignType.color : DEFAULT_COLOR);
        })
        return typeColorMap;
    }

    const getTypesId = (stream) => {
        if (stream === undefined || stream === null || stream.campaignTypes.length === 0) return [];
        let typesId = [];
        stream.campaignTypes.forEach( campaignType => {
           typesId.push(campaignType.typeId)
        })
        return typesId;
    }

    const [state, setState] = useState({
        name: stream !== null ? stream.streamId : "",
        campaignTypes: getTypesId(stream),
        campaignTypesColors: getTypeColorsMap(stream),
        priority: stream !== null ? stream.priority : 0,
        isLoading: false,
        allowedTypeIds: types != null ? getAllowedTypes(types, streamsList) : [],
        errors: {}
    });

    useEffect(()=>{
        if (updateStreamResponse.isSuccess && !updateStreamResponse.isUninitialized) {
            setState({
                ...state,
                isLoading: false
            })
            onClose();
        }

    }, [updateStreamResponse])

    useEffect(()=>{
        setState({
            ...state,
            allowedTypeIds: types != null ? getAllowedTypes(types, streamsList) : []
        })
    }, [types])

    const handleChangeName = (event) => {
        setState({
            ...state,
            name: event.target.value,
        })
    }
    const handleChangeColor = (type, color) => {
        let colorMap = new Map (state.campaignTypesColors);
        colorMap.set(type, color.hex);
        setState({
            ...state,
            campaignTypesColors: colorMap,
        })
    }
    const handleChangeAllowedTypes = (value) => {
        let updatedAllowedTypes = getAllowedTypes(types, updateStreamsList(state.name, streamsList, value));
        setState({
            ...state,
            campaignTypes: value,
            allowedTypeIds: updatedAllowedTypes
        })
    }
    const findStateColorForType = (type) => {
        return state.campaignTypesColors.get(type) !== undefined ? state.campaignTypesColors.get(type): "";
    }

    const updateStreamsList = (streamId, streamsList, newValues) => {
        let updatedStreamsList = [];
        streamsList.forEach(stream => {
            let newCampaingTypeList = [];
            if (stream.streamId === streamId) {
                newValues.forEach(v => {
                    newCampaingTypeList.push({
                        typeId: v,
                        color: DEFAULT_COLOR,
                        priority: 0
                    })
                })
            } else {
                newCampaingTypeList = stream.campaignTypes;
            }
            updatedStreamsList.push({
                streamId: stream.streamId,
                priority: stream.priority,
                campaignTypes: newCampaingTypeList
            })
        })
        return updatedStreamsList;
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.name <= 0) {
            errors = {...errors, name: true};
        }
        if (state.campaignTypes.length === 0) {
            errors = {...errors, campaignTypes: true};
        }
        setState({...state, errors: errors})
        return Object.keys(errors).length === 0;
    }

    const getCampaignTypes = ()  => {
        let campaignTypesUpdate = [];
        state.campaignTypes.forEach(type =>{
            campaignTypesUpdate.push({
                typeId: type,
                color: state.campaignTypesColors.get(type) != null ? state.campaignTypesColors.get(type) : DEFAULT_COLOR,
                priority: 0
            })
        })
        return campaignTypesUpdate;
    }

    const handleSave = () => {
        if (validateRequiredFields()) {
            updateStream({
                streamId: state.name,
                name: state.name,
                priority: state.priority,
                campaignTypes: getCampaignTypes()
            })
            setState({
                ...state,
                isLoading: true
            })
        }
    }


    let getContent = () => {
        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                       disabled={!isCreateStream}
                                       name="streamName"
                                       label="Name"
                                       value={state.name}
                                       error={state.errors.name}
                                       helperText={state.errors.name
                                           ? "Field should not be empty"
                                           : ""}
                                       onChange={handleChangeName}>
                            </TextField>
                            <FormHelperText style={{color: "red"}}>
                                {state.errors.name}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                value={state.campaignTypes}
                                options={state.allowedTypeIds}
                                onChange={(e, v) =>
                                    handleChangeAllowedTypes(v)}
                                renderInput={params => (
                                    <TextField {...params}
                                               fullWidth
                                               label="Campaing types"
                                               error={state.errors.campaignTypes}

                                    />
                                )}>

                            </Autocomplete>
                            {state.errors.campaignTypes &&
                                <p className="error-helper-text-center">
                                    *Choose at least one campaign type for a stream
                                </p>}
                        </Grid>
                        <Grid item xs={12}>
                            {(state.campaignTypes && state.campaignTypes.length !== 0) &&
                            <Box>
                                <Divider/>
                                <Typography component="body" variant="body"  sx={{mb:2}}>
                                    Select color for campaign type:
                                </Typography>
                            {
                                state.campaignTypes && state.campaignTypes.sort().map((type, index) =>
                                    <Box  sx={{mb:1}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                {type}
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TwitterPicker
                                                    colors = {DEFAULT_COLORS}
                                                    width={"100%"}
                                                    triangle={"hide"}
                                                    color={ findStateColorForType(type) }
                                                    onChangeComplete={ (color) => handleChangeColor(type, color) }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )


                            }
                            </Box>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={state.isLoading} variant={'contained'} onClick={handleSave}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </>
        )
    }

    return (
        <>
            <CommonModal
                open={open}
                handleDialogClose={onClose}
                title={title}
                content={getContent()}
            />
        </>
    )

}