import {Fragment, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {schedulerConfig} from "./TimelimeConfig";
import {BryntumScheduler} from "@bryntum/scheduler-react";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {useGetEventsQuery} from "../core/reducers/calendar";
import {calendarDateOn, getEnvName, stringToDate} from "../components/Utils/CommonUtils";
import CampaignToolbarTypeSwitch from "../components/Publisher/Campaign/CampaignToolbarTypeSwitch";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

export default function Timeline(props) {
    const { resource, events, isDataReady, isError, error, eventsDateFrom, calendarEvents} = props;
    const RED_COLOR = '#fb5609';
    const YELLOW_COLOR = '#F2FA0A';
    const GREED_COLOR = '#0AFA46';

    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [env1, setEnv1] = useState(getEnvName('env', sourceMode, searchParams))
    const [env2, setEnv2] = useState(getEnvName('env2', sourceMode, searchParams))

    const navigate = useNavigate()
    //const currentTime =  Math.round(new Date(eventsDateFrom).setHours(0,0,0,0)/1000)
    //const {data: calendarEvents, isUninitialized: isUninitializedEvents, isSuccess, refetch} = useGetEventsQuery(currentTime);

    const scheduler = useRef();

    const [state, setState] = useState({
        resources: [],
        events: [],
        isDataReady: false,
        calendarSyncChecked: localStorage.getItem('calendarSyncChecked') ? JSON.parse(localStorage.getItem('calendarSyncChecked')) : false,
        calendarEvents: [],
        eventsBuffer: [],
    });

    const findEventInCalendar = (event) => {
        let calendar = null;
        let cachedEvents = localStorage.getItem('calendar') ?
            JSON.parse(localStorage.getItem('calendar')):
            [];
        let eventsBuf = [];
        if (state.calendarEvents.length === 0 && cachedEvents.length === 0) return calendar;
        if (state.calendarEvents.length === 0 && cachedEvents.length !== 0) {
            eventsBuf = cachedEvents;
        } else {
            eventsBuf = state.calendarEvents;
        }
        eventsBuf.forEach(calendarEvent => {
            if (calendarEvent.eventId !== null && event.name !== null && calendarEvent.eventId.trim() === event.name.trim()) {
                calendar =  calendarEvent;
            }
        })
        return calendar;
    }

    const compareDates = (calendarEvent, event) => {
        const calendarDateStart = calendarDateOn(calendarEvent.dueOn);
        const eventDateStart = calendarDateOn(event.startDate);
        let isStartEq = false;
        if (calendarDateStart.getTime() === eventDateStart.getTime()) {
            isStartEq = true;
        }

        let isEndEq = false;
        const calendarDateEnd = calendarDateOn(calendarEvent.endDate);
        const eventDateEnd = stringToDate(event.endDate);
        if (calendarDateEnd.getTime() === eventDateEnd.getTime()) {
            isEndEq = true;
        }
        return isStartEq && isEndEq;
    }

    const getCheckedCalendarEvents = (events) => {
        let updatedEvents = [];
        if (events.length === 0) return updatedEvents;
        events.forEach(event => {
            const calendarEvent = findEventInCalendar(event);
            let updatedEvent = JSON.parse(JSON.stringify(event));
            if (calendarEvent === null) {
                updatedEvent.eventColor = RED_COLOR;
            } else {
                if (compareDates(calendarEvent, event)) {
                    updatedEvent.eventColor = GREED_COLOR;
                } else {
                    updatedEvent.eventColor = YELLOW_COLOR;
                }

            }
            updatedEvents.push(updatedEvent);
        })
        return updatedEvents;
    }

    const checkEventsInCalendar = () => {
        setState({
            ...state,
            eventsBuffer: state.events,
            events:  getCheckedCalendarEvents(state.events)
        })
    }

    useEffect(() => {
        if (calendarEvents) {
            setState({
                ...state,
                calendarEvents: calendarEvents
            })
            localStorage.setItem('calendar', JSON.stringify(calendarEvents))

        }
        console.log(calendarEvents)

    }, [calendarEvents])

    useEffect(() => {
        if (state.calendarSyncChecked) {
            localStorage.setItem('calendarSyncChecked', JSON.stringify(true))
            checkEventsInCalendar();
        } else {
            localStorage.setItem('calendarSyncChecked', JSON.stringify(false))

            setState({
                ...state,
                events: state.eventsBuffer
            })
        }

    }, [state.calendarSyncChecked])

    useEffect(() => {
        if (isDataReady && events != null) {
            setState({
                ...state,
                events: state.calendarSyncChecked ? getCheckedCalendarEvents(events) :events,
                eventsBuffer: state.calendarSyncChecked ? events : getCheckedCalendarEvents(events),
                resources: resource.sort((a, b) => a.priority - b.priority),
                isDataReady: true,
            })
            if (scheduler !== undefined && scheduler !== null && scheduler.current !== null && scheduler.current !== undefined) {
                console.log(scheduler)
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 7);
                scheduler.current.schedulerInstance.scrollToDate(yesterday, {block: "start"});            }
        }
        if (!isDataReady) {
            setState({
                ...state,
                isDataReady: false
            })
        }
    }, [resource, events, isDataReady, isError, error]);


    if (!isDataReady) {
        return <CircularProgress />;
    }
    if (isError) {
        return <h2 style={{ margin : 'auto' }}>{`Error: ${error.error}`}</h2>;
    }

    const handleChange = (event) => {
        setState({
            ...state,
            calendarSyncChecked: event.target.checked
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CampaignToolbarTypeSwitch
                    onSwitchMode={(type) => {
                        if (type == 'timeline') return false
                        if (type == 'type') navigate(`/publisher/campaigns?env=${env1}&env2=${env2}&viewType=type`);
                        if (type == 'recent') navigate(`/publisher/campaigns?env=${env1}&env2=${env2}&viewType=recent`);
                    }}
                    mode={'timeline'}
                />
                <FormControlLabel
                    label="Calendar Check"
                    control={
                        <Checkbox
                            checked={state.calendarSyncChecked}
                            onChange={handleChange}
                            sx={{
                                '&.Mui-checked': {
                                    color: '#03907FFF',
                                },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                />
            </Box>

            { state.isDataReady ?
                <Box>

                <BryntumScheduler
                    ref={scheduler}
                    {...schedulerConfig}
                    resources={state.resources}
                    events={state.events}
                />
                </Box> :
                <h2 style={{ margin : 'auto' }}>Not started.</h2>
            }
        </>
    );
}

Timeline.propTypes = {
    resource: PropTypes.array,
    events: PropTypes.array,
    isDataReady: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.object,
    eventsDateFrom: PropTypes.string,
    calendarEvents: PropTypes.array
}