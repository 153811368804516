import {Box} from "@mui/system";
import {useState} from "react";
import PropTypes from "prop-types";
import {useDeleteStreamMutation} from "../../../core/reducers/streams";
import {Card, CardActions, CardContent, Chip, Grid, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UpdateStreamDialog from "./UpdateStreamDialog";
import { styled } from '@mui/system';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteStreamDialog from "./DeleteStreamDialog";


function CustomChip({ label, color }) {
    return (
        <Chip
            label={label}
            sx={{
                mr:1,
                mb:1,
                backgroundColor: '#ffffff', // Use default if color isn't provided
                color: color || '#3498db', // Ensure text contrast
                border: `1px solid ${color || '#2980b9'}`, // Border color with default
            }}
        />
    );
}
export default function StreamCard(props) {
    const {stream, streamsList, allowedTypes, refetchSteams} = props;
    const [state, setState] = useState({
        isResponseDialogOpen: false,
        responseData: {
            isUpdate: false,
            responseData: {}
        },
        isEditStreamDialog: false,
        isDeleteStreamDialogOpen: false,
    });
    const handleEditButton = () => {
        setState({
                ...state,
                isEditStreamDialog: true
            }
        )
    }

    const handleDelete = () => {
        setState({
                ...state,
                isDeleteStreamDialogOpen: true
            }
        )
    }

    const handleCloseDialog = () => {
        setState({
                ...state,
                isEditStreamDialog: false
            }
        )
        refetchSteams()
    }

    const handleCloseDeleteDialog = () => {
        setState({
                ...state,
                isDeleteStreamDialogOpen: false
            }
        )
        refetchSteams();
    }


    return <Box sx={{p: 1, width:'100%'}}>
        <Card sx={{display: 'flex', width:'100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
            </Box>
            <CardContent sx={{ flex: '1 0 auto'}}>
                <Grid container sx={{m: 1}} spacing={1}>
                    <Grid xs = {10}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                mb: 2
                            }}
                            gutterBottom
                            component="div">
                            {stream.streamId}
                        </Typography>
                    </Grid>
                    <Grid xs = {2} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <IconButton
                            aria-label="edit"
                            onClick={() => handleEditButton()}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete()}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>

                </Grid>

                <Box className = {"campaign-types-list"}>
                    {
                        stream.campaignTypes.map((type, index) =>
                            <CustomChip
                                label={type.typeId}
                                color={type.color}
                                />
                        )

                    }
                </Box>
            </CardContent>
        </Card>
        <UpdateStreamDialog
            open={state.isEditStreamDialog}
            onClose={handleCloseDialog}
            title={"Update stream configuration"}
            stream={stream}
            streamsList={streamsList}
            types={allowedTypes}
            isCreateStream={false}
        />
        <DeleteStreamDialog
            open={state.isDeleteStreamDialogOpen}
            onClose={handleCloseDeleteDialog}
            title={"Delete stream"}
            stream={stream}
        />
    </Box>
}
StreamCard.propTypes = {
    stream: PropTypes.object.isRequired,
    streamsList: PropTypes.array.isRequired,
    allowedTypes: PropTypes.array.isRequired,
    refetchSteams: PropTypes.func.isRequired,
};