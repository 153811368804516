import {calendarApi} from "../api/base-api";

const calendarEventsApi = calendarApi.injectEndpoints({
    endpoints: (build) => ({
        getEvents: build.query({
            query: (startDate) => {
                return {
                    url: `/events?startDate=${startDate}`
                }
            },
        }),
    })
})
export const {
    useGetEventsQuery,
} = calendarEventsApi;