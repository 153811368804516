import React, {useState, useEffect, useRef} from 'react';
import CampaignGroup from "./CampaignGroup";
import {useGetAllCampaignsQuery} from "../../../core/reducers/campaign";
import Loading from "../../Loading";
import {sortCampaign, sortCampaignByDistribution} from "../../Utils/CommonUtils";
import {CampaignItemContext} from "../../../core/context/context";
import {Alert} from "@mui/lab";
import {Button, Card, Menu, MenuItem} from "@mui/material";
import {getDomainAlias} from "../../../constants";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CSVLink } from "react-csv";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignToolbar from "./CampaignToolbar";
import {useSearchParams} from "react-router-dom";


export default function CampaignFrame({
                                          env,
                                          onSelectItem,
                                          filterText,
                                          selectedList,
                                          externalLinks,
                                          filterCampaignType,
                                          filterStatuses,
                                          filterType,
                                          rightPanel,
                                          createDisabled,
                                          dateFilter,
                                          setCalendarMode

                                      }) {


    const [showAllGroups, setShowAllGroups] = useState(null);
    const [showRainbow, setShowRainbow] = useState(window.localStorage.getItem("show_rainbow_" + env) == null ? false : window.localStorage.getItem("show_rainbow_" + env) == 'true')

    const [searchParams, setSearchParams] = useSearchParams();
    const [sortByType, setSortByType] = useState(searchParams.get('viewType')==null||searchParams.get('viewType') == 'type');

    const query = {}
    query.env = env
    if(dateFilter!=undefined && dateFilter.from!= '' && dateFilter.to != '') {

        query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
        query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);

    }


    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllCampaignsQuery(query, {
        pollingInterval: 5000,
    });



    const timeGroups = [
        'All types by Campaign Start',
        'Infinite Campaigns (float)',
        'Infinite Campaigns (permanent)'
    ]

    function getPriority(item) {
        const campaignType = campaignTypesByType[item.type]

        let current_time = new Date().getTime() / 1000;

        if (current_time < (item.distribution.from - campaignType.announceDuration)) return 1; // aqua
        if (
            current_time >= (item.distribution.from - campaignType.announceDuration) &&
            current_time < item.distribution.from
        ) return 2; // lightgreen
        if (
            current_time >= item.distribution.from &&
            current_time < item.distribution.till
        ) return 3; // green
        if (
            current_time >= item.distribution.till &&
            current_time < (item.distribution.till + campaignType.extraDuration)
        ) return 4; // yellow
        if (current_time >= (item.distribution.till + campaignType.extraDuration)) return 5; // red
    }

    function sortItemsByTime(a, b) {
            const priority = getPriority(a) - getPriority(b);
            if(priority == 0) {
                return (a.distribution.from - b.distribution.from) < 0 ? 1 : -1
            }
            return priority
    }

    const getSorted = (campaigns) => {
        return campaigns.campaigns.reduce((acc, campaign) => {

            if (filterText !== '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

            if (filterCampaignType !== '') {
                let campaignType = campaigns.types.filter(item => item.readableType === campaign.type)[0]
                if (campaignType.contentType !== filterCampaignType) return acc;
                //if(filterCampaignType!= '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;
            }

            if (filterStatuses !== undefined && filterStatuses.length > 0) {
                if (!filterStatuses.includes(campaign.status)) return acc;
            }

            if (filterType !== undefined && filterType !== 'All' && filterType != null) {
                if (campaign.type != filterType) return acc;
            }

            let type = timeGroups[0];
            if(campaign.distribution.type=='fixed') {}
            if(campaign.distribution.type=='float' && campaign.distribution.till >= 32503680000) {
                type = timeGroups[1]
            } else if(campaign.distribution.type=='permanent' && campaign.distribution.till >= 32503680000) {
                type = timeGroups[2]
            }

            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(campaign);

            return acc;
        }, {})
    }
    const getGrouped = (campaigns) => {
        return campaigns.campaigns.reduce((acc, campaign) => {
            const type = campaign.type;

            if (filterText !== '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;

            if (filterCampaignType !== '') {
                //let campaignType = campaigns.types.filter(item => item.readableType === type)[0]
                let campaignType = campaignTypesByType[type]
                if (campaignType.contentType !== filterCampaignType) return acc;
                //if(filterCampaignType!= '' && campaign.id.toLowerCase().indexOf(filterText.toLowerCase()) < 0) return acc;
            }

            if (filterStatuses !== undefined && filterStatuses.length > 0) {
                if (!filterStatuses.includes(campaign.status)) return acc;
            }

            if (filterType !== undefined && filterType !== 'All' && filterType != null) {
                if (campaign.type != filterType) return acc;
            }

            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(campaign);

            return acc;
        }, {})
    }

    const campaignTypesByType = campaigns==undefined ? {} : campaigns?.types.reduce((acc, item) => {
        acc[item.readableType] = item;
        return acc;
    }, {});
    const campaignsByType = campaigns == undefined ? [] : sortByType?getGrouped(campaigns): getSorted(campaigns);




    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;
    useEffect(() => {

        if(prevProps==undefined || (prevPropsRef.current != env && error==undefined && !isLoading && !isFetching)) {
            prevPropsRef.current = env
        }
    }, [env, isLoading, isFetching, error]);


    const isNotError = (error==''||error==undefined) || (error?.data?.message?.indexOf('Cursor')>0 && prevPropsRef.current==env)
    const shouldShowContent = (!isLoading&&prevPropsRef.current==env || (prevPropsRef.current!=env&&!isFetching)) && isNotError &&(( !isLoading && !isError) || (prevPropsRef.current==env))
    const shouldShowLoading = ((error!=''&&error!=undefined&&error?.data?.message?.indexOf('Cursor')==-1) || isLoading || (isError && prevPropsRef.current!=env && error?.data?.message?.indexOf('Cursor')==-1) || (isFetching && prevPropsRef.current!=env && prevPropsRef.current!=undefined))

    const generateCSVData = () => {
    const csvData = [];
    Object.keys(campaignsByType).forEach((type) => {


        campaignsByType[type].forEach((campaign) => {
            const campaignType = campaignTypesByType[campaign.type];
            const bundle = campaigns.bundles.filter(item => item.id==campaign.bundleId)[0]
            const type = campaigns.types.filter(item => item.readableType === campaign.type)[0]

            const row = {
                id: campaign.id,
                groupId: campaign.groupId || "",
                priority: parseInt(campaign.priority),
                status: campaign.status || "",
                type: campaign.type || "",
                bundleId: campaign.bundleId || "",
                g1: bundle.generations['1']?.bundleVersion || "",
                g2: bundle.generations['2']?.bundleVersion || "",
                g3: bundle.generations['3']?.bundleVersion || "",
                g4: bundle.generations['4']?.bundleVersion || "",
                g5: bundle.generations['5']?.bundleVersion || "",
                g6: bundle.generations['6']?.bundleVersion || "",
                g7: bundle.generations['7']?.bundleVersion || "",
                g8: bundle.generations['8']?.bundleVersion || "",
                g9: bundle.generations['9']?.bundleVersion || "",
                g10: bundle.generations['10']?.bundleVersion || "",
                matchmakingType: type.matchmakingTypes!=null?type.matchmakingTypes.join(', ') : "",
                tag: campaign.tags!=null?campaign.tags.join(', ') : "",
                fickle: campaign.fickle == true ? "true" : "false",
                repeatable: campaign.repeatable == true ? "true" : "false",
                announceFrom: campaign.distribution.from - campaignType.announceDuration,
                from: campaign.distribution.from,
                till: campaign.distribution.till,
                extraTimeTill: campaign.distribution.till + campaignType.extraDuration,
                audiences: JSON.stringify(campaign.audiences || [])
            };
            csvData.push(row);
        });
    });
    return csvData;
    };

    return (
        <div>
            {shouldShowLoading && <Card  sx={{mt:3.5}} severity="info"><Loading env={env} isLoading={isLoading||isFetching} isError={isError} error={error} refetch={refetch}></Loading></Card>}

            {shouldShowContent&&Object.keys(campaignsByType).length == 0 && <Alert sx={{mt:3.5}} severity="info">No campaigns</Alert>}
            {shouldShowContent&&Object.keys(campaignsByType).length > 0 && <>

                <CampaignToolbar
                        setShowAllGroups={setShowAllGroups}
                        setShowRainbow={setShowRainbow}
                        setSortByType={setSortByType}
                        setCalendarMode={setCalendarMode}
                        sortByType={sortByType}
                        showAllGroups={showAllGroups}
                        showRainbow={showRainbow}
                        generateCSVData={generateCSVData}
                        env={env}
                />


                {Object.keys(campaignsByType).sort().map((type) => (
                    <CampaignItemContext.Provider
                        key={type}
                        value={{
                            externalLinks,
                            env,
                            selectedList,
                            onSelectItem,
                            bundles: campaigns.bundles,
                            createDisabled,
                            type,
                            campaigns: campaigns,
                            types: campaignTypesByType,
                            campaignType: campaignTypesByType[type],
                            items: sortByType&&getDomainAlias() != 'cm' ? campaignsByType[type].sort(sortCampaign) : campaignsByType[type].sort(sortItemsByTime)
                        }}>
                        <CampaignGroup showRainbow={showRainbow} showAllGroups={showAllGroups} rightPanel={rightPanel} />
                    </CampaignItemContext.Provider>
                ))}
            </>}

        </div>
    );
};
